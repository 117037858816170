<template>
	<!-- flat-accordion -->
    <section class="tf-section wrap-accordion">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="tf-title-heading ct style-2 fs-30 mg-bt-10">
                        Frequently Asked Questions
                    </h2>
                    <!--
					<h5 class="sub-title help-center mg-bt-32 ">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                    </h5>
					
					-->
                </div>
                <div class="col-md-12">
                    <div class="flat-accordion2">
                        <div class="flat-toggle2">
                            <h6 class="toggle-title active">What is BlueDiamonds?</h6>
                            <div>
                            <p>BlueDiamonds is a user-centric and reward-based Solana Marketplace, that makes buying and selling NFTs easy, enjoyable and profitable. 
                            </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">Why did you decide to launch BlueDiamonds? </h6>
                            <div>
                            <p>With all due respect, as members of the Solana Community and users of the various marketplaces, we felt that there was still a demand for a very good marketplace. The existing ones provide an OK experience at most. We have already identified various key missing features that will bring real added value to the game. By launching BlueDiamonds, we aspire to give the Solana community the tool they deserve.</p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">What's the BlueDiamonds team mindset?</h6>
                            <div>
                            <p>Team wise, we have the capacity to deliver fast and well (full stack in house dev team) and to manage the product roadmap. We’re fully agile, and will keep delivering value over time, again and again. We are not satisfied with anything less than excellence, and we strive for even better, again and again. </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">What makes BlueDiamonds stand out? Key unique features? </h6>
                            <div>
                            <p>Our core offering contains various differentiators that sets us apart:<br>
- 1) An impeccable UX (We love it and attach great importance to it ) <br>
- 2) The right information at the right place (We reduce the number of clicks by providing the most important info at a glance) <br>
- 3) Our model includes an automatic reward mechanism (20% of our revenue go to our users) <br>
- 4) We offer various features for the sellers/buyers<br>
- 5) We offer collection owners specific features</p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">What are the key differentiating features? </h6>
                            <div>
                            <p>As true NFT lovers, we identified all the features we dreamed of having on a marketplace, and built them. Among others:<br>  
- Reward Mechanism (details below)<br>
- Integrated rarity ranking<br>
- Fav NFTs/Collections<br>
- Notifications (purchase/offer/favs)<br>
- Portfolio PnL<br>
- Collection in-depth stats<br>
- Collection tags<br>
- All collections filters<br>
- Profile page<br>
- In app badges (that will strengthen the community feeling) <br>
- … <br><br>
And MUCH MORE to come!
</p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">What is the reward mechanism? </h6>
                            <div>
                            <p>The more you buy/sell, the more you get: 20% of our revenue go to BlueDiamonds users via our native $BD token <br>
For every ◎ worth of NFTs that you buy or sell on BlueDiamonds, we airdrop you 20 $BD tokens that provide you with many exciting perks: <br>
- 1) All tokens will be exchangeable ($) on token exchanges (Raydium etc.)<br>
- 2) Priority access to newly listed NFTS (based on # of $BD tokens held)<br>
- 3) Priority access to selected minting collections (based on # of $BD tokens held)<br>
- 4) Ability for sellers to get your NFTs featured on our front page<br>
- 5) Key utility in the upcoming BlueDiamonds collection
 </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">When will the $BD token be released? When will it be listed on exchange?</h6>
                            <div>
                            <p>$BD tokens are already released (reward mechanism already active). <br>
They will be listed on exchange in a couple of months once our custom private collection is launched. </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">I'm collection owner, why should I join BlueDiamonds? How do I proceed? </h6>
                            <div>
                            <p>We have performed a detailed survey with collection owners and identified various key missing features that we offer (both for existing and upcoming collections). <br>
Apply for listing and discover our exclusive welcome package! 
</p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">What are the fees? </h6>
                            <div>
                            <p>BlueDiamonds doesn’t charge any listing/unlisting/offer fees. <br>
We have a 2% flat seller fee which is taken at the time of the sale.<br>
We honor the royalties set by creators (You can check the royalty % set by each creator on the item detail page). </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">I'm an NFT buyer/seller, why should I join BlueDiamonds? How do I proceed? </h6>
                            <div>
                            <p>Team wise, we have the capacity to deliver fast and well (full stack in house dev team) and to manage the product roadmap. We’re fully agile, and will keep delivering value over time, again and again. We are not satisfied with anything less than excellence, and we strive for even better, again and again. </p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">Who is the team behind BlueDiamonds? 
</h6>
                            <div>
                            <p>Our confounders are product and tech lovers with the right complementary skillset, combined with a big dose of motivation (and imagination). . With more than 10 years of experience in software development and product management, our co-founders lead a full stack team to continuously deliver valuable features. All team members are digital natives and NFT enthusiasts. Ambitious, we want to push the existing boundaries and provide the Solana community the tool they deserve.</p>
                            </div>
                        </div>
                        <div class="flat-toggle2">
                            <h6 class="toggle-title">When will the BlueDiamonds private collection be launched? </h6>
                            <div>
                            <p>The artworks of the collection are being finalized.<br>
Our website will soon be online. It will showcase some of our NFTs and detail the unique concept of our collection, which will strengthen our ecosystem.<br>
Stay tuned, more to be announced very soon. </p>
                            </div>
                        </div>                              
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- flat-accordion -->  
</template>

<script>

import $ from 'jquery';

export default {
  name: 'Faq',
  
  created () {
  
  },
  mounted () {
	window.scrollTo(0, 0);
	
	
	
  },
}

 

</script>